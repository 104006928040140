import { LINKED_VIS } from "@/utils/constants";
import { upperCase } from "@/utils/helpers";
import { getAssetFormats } from "@/utils/data";

export const useSharePost = () => {
	const requestDetails = (
		postType,
		postContent,
		postExternal,
		userIds
	) => {
		//Get URN and provider token
		const { urn, providerToken } = userIds;
		const mediaProperties = ref({});

		//Universal details that apply to all posts
		const universalDetails = {
			author: `urn:li:person:${urn}`,
			lifecycleState: "PUBLISHED",
		};

		if (postType === "document" && postExternal) {
			//Media properties
			mediaProperties.value = {
				media: [
					{
						status: "READY",
						originalUrl: `${postExternal.url}`,
						title: {
							text: `${postExternal.title}`,
						},
						description: {
							text: `${postExternal.description}`,
						},
					},
				],
			};
		}

		//Post construction
		const post = {
			postDetails: {
				...universalDetails,
				specificContent: {
					"com.linkedin.ugc.ShareContent": {
						shareCommentary: {
							text: postContent,
						},
						shareMediaCategory:
							postType !== "text"
								? `${upperCase(postType)}`
								: "NONE",
						...(mediaProperties.value && {
							mediaProperties: mediaProperties.value,
						}),
					},
				},
				visibility: {
					"com.linkedin.ugc.MemberNetworkVisibility":
						LINKED_VIS,
				},
			},
			sessionDetails: {
				providerToken,
			},
		};

		return post;
	};

	//For LinkedIn images and videos only. Register asset with LinkedIn first.
	const registerAsset = async (postType, userIds) => {
		const { urn, providerToken } = userIds;

		const registerUploadRequestPost = {
			postDetails: {
				registerUploadRequest: {
					recipes: [
						`urn:li:digitalmediaRecipe:feedshare-${postType}`,
					],
					owner: `urn:li:person:${urn}`,
					serviceRelationships: [
						{
							relationshipType: "OWNER",
							identifier: "urn:li:userGeneratedContent",
						},
					],
				},
			},
			sessionDetails: {
				providerToken,
			},
		};

		const { data, error } = await useFetch(
			"/api/publish/post/register",
			{
				method: "POST",
				body: registerUploadRequestPost,
			}
		);

		if (error) {
			return false;
		}

		if (data) {
			return true;
		}
	};

	const getProviderToken = async () => {
		//Get URN
		const user = useSupabaseUser();
		const identities = user.value.identities;
		const urn = identities[0].id;

		//Get provider token
		const client = useSupabaseAuthClient();
		const session = await client.auth.getSession();
		const sessionDetails = session;

		const providerToken =
			sessionDetails.data.session.provider_token;

		if (!providerToken) {
			console.error("Provider token is not available");
			return;
		}

		return { urn, providerToken };
	};

	const sharePost = async (
		postType,
		postContent = null,
		postExternal = null
	) => {
		try {
			//1. Get provider/access token
			const userIds = await getProviderToken();

			//2. If image/video, register asset with LinkedIn first
			// const isAssetFormat = getAssetFormats(postType);
			// console.log("isAssetFormat", isAssetFormat);

			// if (isAssetFormat) {
			// 	const registrationData = await registerAsset(
			// 		postType,
			// 		userIds
			// 	);

			// 	console.log("registrationData", registrationData);
			// }

			//Temp return
			return true;
		} catch (error) {
			console.error(error);
		}

		//2. Construct post details
		// const body = requestDetails(
		// 	postType,
		// 	postContent,
		// 	postExternal,
		// 	userIds
		// );
		// console.log("Posting to platform", body);

		//3. Post to platform
		// const { data, error } = await useFetch(
		// 	"/api/publish/post",
		// 	{
		// 		method: "POST",
		// 		body,
		// 		headers: useRequestHeaders(["cookies"]),
		// 	}
		// );

		//4. Check if post was successful
		// const isPosted = error ? false : true;

		// console.log("Posted to platform", data);
		// console.log("Error posting to platform", error);

		//5. Return status
		//return isPosted;
	};

	//Return functions
	return {
		sharePost,
	};
};
