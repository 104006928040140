//FIXME: Server-side sesson management must be implemented before deploying to the next phase of beta testing.

export const useSupabaseAuth = () => {
	const supabase = useSupabaseAuthClient();

	const signInWithEmailAdminOnly = async (
		email,
		password
	) => {
		//Clear previous sessions and cookies
		clearLastSession(supabase);

		//Sign in with email and password
		const { data, error } =
			await supabase.auth.signInWithPassword({
				email,
				password,
			});

		if (data) {
			return true;
		}

		if (error) {
			console.error(error);
			return false;
		}
	};

	const signInWithLinkedIn = async () => {
		const { error } = await supabase.auth.signInWithOAuth({
			provider: "linkedin_oidc",
			options: {
				redirectTo:
					process.env.NODE_ENV === "development"
						? "http://localhost:3000/confirm"
						: "https://app.voiragency.co.uk/confirm",
				scopes: "openid profile email w_member_social",
			},
		});

		if (error) {
			console.error(
				"Error signing in with LinkedIn",
				error
			);
			return null;
		}
	};

	return {
		signInWithEmailAdminOnly,
		signInWithLinkedIn,
	};
};

export const useSupabaseStorageClient = () => {
	const supabase = useSupabaseAuthClient();

	const viewAssets = async (bucket, assets) => {
		const getAssetUrl = async asset => {
			const { data, error } = supabase.storage
				.from(bucket)
				.getPublicUrl(asset.name);
			if (error) {
				console.error(error);
				return null;
			}

			return data;
		};

		return Array.isArray(assets)
			? Promise.all(assets.map(asset => getAssetUrl(asset)))
			: getAssetUrl(assets);
	};

	return {
		viewAssets,
	};
};

export const clearLastSession = async () => {
	const supabase = useSupabaseAuthClient();

	const { error } = await supabase.auth.signOut();

	//Clear any existing sessions
	await supabase.auth.signOut();

	//Clear local storage
	localStorage.clear();

	//Deal with the error
	if (error) {
		console.error("Error logging out:", error);
	}
};
