import validate from "/workspace/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/workspace/middleware/01.auth.global.js";
import _02_45hydrate_45global from "/workspace/middleware/02.hydrate.global.js";
import development_45global from "/workspace/middleware/development.global.js";
import manifest_45route_45rule from "/workspace/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45hydrate_45global,
  development_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}