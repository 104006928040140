import { defineStore } from "pinia";

export const useNotificationStore = defineStore(
	"notifications",
	{
		state: () => ({
			notifications: {},
			error: null,
			recipientDetails: {
				client: {
					firstName: "",
					lastName: "",
					email: "",
					id: "",
				},
				creator: {
					firstName: "",
					lastName: "",
					email: "",
					id: "",
				},
				assignee: {
					firstName: "",
					lastName: "",
					email: "",
					id: "",
				},
			},
			currentNotification: {},
		}),
		getters: {
			getNotifications: state => state.notifications,
			allAreHidden: state => {
				//If all notifications are hidden, then return true
				const notifications = state.notifications;
				const allAreHidden = Object.values(
					notifications
				).every(
					notification => notification.meta.isRead === true
				);
				return allAreHidden;
			},
			isCreatorAndAssigneeSame: state => {
				const creator = state.recipientDetails.creator;
				const assignee = state.recipientDetails.assignee;
				return creator.id === assignee.id;
			},
		},
		actions: {
			hydrateNotifications(notifications) {
				delete notifications._id;
				this.notifications = notifications;
			},

			async createNotification(notificationSettings) {
				const { makeNotification } =
					useNotificationCreator();

				console.log(
					"notificationSettings",
					notificationSettings
				);

				const response = await makeNotification(
					notificationSettings
				);

				console.log("createNotification", response);
			},

			async updateNotification(nId) {
				const { amendNotification } =
					useNotificationCreator();

				const response = await amendNotification(nId);

				console.log("updateNotification", response);
			},

			setNotificationRecipient(recipient, recipientType) {
				this.recipientDetails[recipientType] = recipient;
			},
		},
	}
);
