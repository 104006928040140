export default defineNuxtRouteMiddleware(
	async (to, from) => {
		//If process.env.NODE_ENV is production
		// if (process.env.NODE_ENV === "production") {
		// 	//Redirect all requests to index
		// 	if (to.path !== "/") {
		// 		return navigateTo("/");
		// 	}
		// } else {
		// 	//Redirect index to login
		// 	if (to.path === "/") {
		// 		return navigateTo("/login");
		// 	}
		// }
		return;
	}
);
