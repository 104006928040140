import { clearLastSession } from "#imports";

export default defineNuxtRouteMiddleware(
	async (to, from) => {
		if (process.server) return;

		//Return all page requests to index if in production
		// if (process.env.NODE_ENV === "production") {
		// 	if (to.path !== "/") {
		// 		return navigateTo("/");
		// 	}
		// }

		//Auth middleware is global, running on every route chane. We first check if the user is already signed in and authenticated via LinkedIn. If they are, we end the middleware as nothing else needs to be done. If they aren't, then we need to hydrate the user with the remaining data needed for the app to function properly.

		//If the user is coming from home or login, we don't need to do anything as they are not signed in yet and should not be signed in until they authenticate with LinkedIn on the log in page. We also clear the previous session to ensure that the user is not signed in with an old session.

		if (
			to.path === "/" ||
			to.path === "/login" ||
			to.path === "/confirm" ||
			to.path === "/test" ||
			to.path === "/admin"
		) {
			clearLastSession();
			return;
		}

		const user = useSupabaseUser();

		if (!user.value) {
			return navigateTo("/login", {
				query: {
					error:
						"You cannot access the app without first logging in. Please do so now. If you don't have an account with us yet, please visit X to sign up.",
					code: "KSERR-011",
				},
			});
		}

		const { currentUser, isSignedIn, isAuthenticated } =
			useUser();

		if (
			currentUser.value &&
			isAuthenticated.value &&
			isSignedIn.value
		) {
			return;
		} else {
			//The user has been verified by LinkedIn or email and via supabase, so now they need hydrating through hydration middleware.
			return;
			// //User now needs to be hydrated.
			// if (isClient.value) {
			// 	if (!user.value) {
			// 		return navigateTo("/login", {
			// 			query: {
			// 				error:
			// 					"You cannot access the app without first logging in. Please do so now. If you don't have an account with us yet, please visit X to sign up.",
			// 				code: "KSERR-004",
			// 			},
			// 		});
			// 	}

			// 	const isValidEmail = ref(false);

			// 	try {
			// 		console.log("Logging in...");
			// 		const email = user.value.email;
			// 		isValidEmail.value = await logIn(email);

			// 		if (isValidEmail.value) {
			// 			console.log("User is signed in.");
			// 			isSignedIn.value = true;
			// 			return;
			// 		} else {
			// 			isSignedIn.value = false;
			// 			return navigateTo({
			// 				path: "/login",
			// 				query: {
			// 					error:
			// 						"There was an error finding your contact details. Please try again. If the problem persists, please contact support.",
			// 					code: "KSERR-006",
			// 				},
			// 			});
			// 		}
			// 	} catch (error) {
			// 		//TODO: Add website/support details to error message
			// 		isSignedIn.value = false;
			// 		return navigateTo({
			// 			path: "/login",
			// 			query: {
			// 				error:
			// 					"You cannot access the app without first logging in. Please do so now. If you don't have an account with us yet, please visit X to sign up.",
			// 				code: "KSERR-002",
			// 			},
			// 		});
			// 	}
			// } else {
			// 	console.log("Admin logged in. Hydrating...");
			// 	isSignedIn.value = true;
			// }
		}
	}
);
