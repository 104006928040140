import { usePostStore } from "@/stores/posts";

export default () => {
	const postStore = usePostStore();
	const post = postStore.post;

	const apiCall = "posts";

	//Check if post is reassigned
	// const checkIfReassigned = () => {
	// 	return postStore.isReassigned;
	// };

	//Add post to queue
	// const addToQueue = async post => {
	// 	try {
	// 		//Add post to queue
	// 		const { data, error } = await useFetch("/api/queue", {
	// 			method: "POST",
	// 			body: post.content,
	// 		});

	// 		if (error) {
	// 			return false;
	// 		}

	// 		return true;
	// 	} catch (error) {
	// 		return false;
	// 	}
	// };

	const updatePostWithCurrentOwner = currentOwner => {
		return {
			...post,
			meta: {
				...post.meta,
				currentOwner,
			},
		};
	};

	//Accept post function
	const setPost = async (
		postStatus,
		currentOwner,
		isRescheduled
	) => {
		//const isReassigned = checkIfReassigned();
		const updatedPost =
			updatePostWithCurrentOwner(currentOwner);

		console.log("updatedPost: ", updatedPost);

		try {
			//Call API to accept post
			const response = await usePOST(
				updatedPost,
				apiCall,
				postStatus,
				isRescheduled
				//isReassigned
			);

			console.log("response: ", response);

			return response;
		} catch (error) {
			//Show error message
			return {
				status: error.status || "ERROR",
				code: error.code || 500,
				message:
					error.message ||
					"Something went wrong when trying to accept the post. Please try again.",
				dialog: "error",
			};
		}
	};

	//Amend post function
	const amendPost = async (
		postStatus,
		currentOwner,
		isRescheduled
		//isRevived
	) => {
		console.log("postStatus: ", postStatus);

		//const isReassigned = checkIfReassigned();

		const updatedPost =
			updatePostWithCurrentOwner(currentOwner);

		try {
			//Call API to amend post
			const response = await usePATCH(
				updatedPost,
				apiCall,
				postStatus,
				isRescheduled
				//isRevived
				//isReassigned
			);

			if (response.value.code === 200) {
				return response;
			}
		} catch (error) {
			//Show error message
			return {
				status: error.status || "ERROR",
				code: error.code || 500,
				message:
					error.message ||
					"Something went wrong when trying to accept the post. Please try again.",
				dialog: "error",
			};
		}
	};

	const createPost = async ({
		postType = "new",
		postStatus,
		currentOwner,
		isRescheduled = false,
		//isRevived = false,
	} = {}) => {
		//If new, usePOST to create new post, else usePATCH to update existing post

		const postResponse = ref(null);

		const action = {
			new: async () => {
				// const isQueued = await addToQueue(post);
				// console.log("isQueued: ", isQueued);
				// isQueued ? await setPost(postStatus) : null;
				postResponse.value = await setPost(
					postStatus,
					currentOwner,
					isRescheduled
				);
			},
			update: async () => {
				postResponse.value = await amendPost(
					postStatus,
					currentOwner,
					isRescheduled
				);
			},
			delete: async () => {
				postResponse.value = await deletePost();
			},
		};

		await action[postType]();
		return postResponse.value;
	};

	return {
		createPost,
	};
};
