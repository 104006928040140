//Fetch stats data from the API
import { usePostStore } from "@/stores/posts";
import { useAdminStore } from "@/stores/admin";
import { useUserStore } from "@/stores/user";
import { USER_LEVELS } from "@/utils/constants";

export default async (
	id,
	userType,
	sendToAdminStore = false
) => {
	const postStore = usePostStore();
	const userStore = useUserStore();

	try {
		const response = ref("");

		if (userType === "client") {
			response.value = await useFetch(`/api/posts/${id}`);
		} else {
			response.value = await useFetch("/api/posts/");
		}

		const { data, code } = response.value.data;

		if (sendToAdminStore) {
			const posts = data.items;
			const adminStore = useAdminStore();
			adminStore.hydratePosts(posts);
			adminStore.currentClient.posts = posts;
		} else if (code === 200) {
			const userLevel = userStore.currentUser.meta.level;
			if (userType === "client") {
				const restrictedPostStatuses =
					USER_LEVELS[userLevel].restricted.postStates;

				//Remove any posts that are in restricted states
				const filteredPosts = data.items.filter(
					post =>
						!restrictedPostStatuses.includes(
							post.meta.currentStatus
						)
				);

				//Add filtered posts to postStore
				const obj = { items: filteredPosts };
				postStore.hydratePosts(obj);
			} else if (userType === "admin") {
				//Extract items arrays from data
				const posts = data.map(item => item.items);

				const flattenedItems = ref("");

				if (userLevel < 3) {
					//Extract posts that have meta.creator same as userStore.currentUser.uId from all arrays in post
					const filteredPosts = posts.map(item =>
						item.filter(
							post =>
								post.meta.creator ===
								userStore.currentUser.uId
						)
					);

					flattenedItems.value = filteredPosts.flat();
				} else {
					//Flatten items array
					flattenedItems.value = posts.flat();
				}

				//Add flattened items to postStore
				const obj = { items: flattenedItems };
				postStore.hydratePosts(obj);
				return true;
			}
			postStore.isFetching = false;
		} else {
			postStore.error = data.message;
			postStore.isFetching = false;
		}
	} catch (error) {
		postStore.error = error.message;
	}
};
