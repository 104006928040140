import { usePostStore } from "@/stores/posts";
import { lineBreaks } from "@/utils/helpers";

//Send email notification to both sender and recipient
export const useEmail = () => {
	//Send email
	const sendEmail = async (
		recipientDetails,
		currentNotificationData
	) => {
		//Get post content to add to email
		const postStore = usePostStore();
		const url = useRequestURL();

		const emailBody = {
			client: {
				...currentNotificationData.details.email.client,
				...recipientDetails.client,
			},
			creator: {
				...currentNotificationData.details.email.creator,
				...recipientDetails.creator,
			},
			assignee:
				recipientDetails.assignee === null
					? null
					: {
							...currentNotificationData.details.email
								.assignee,
							...recipientDetails.assignee,
						},
			post: {
				subject: postStore.post.subject,
				content: lineBreaks(postStore.post.content),
				link: `${url.origin}/posts/${postStore.post.meta.client}/${postStore.getCurrentClientsPostListLength + 1}`,
			},
		};

		const response = await useFetch("/api/email", {
			method: "POST",
			body: emailBody,
		});
	};

	return { sendEmail };
};
