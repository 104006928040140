//Fetch stats data from the API
import { useStatsStore } from "@/stores/statistics";

export default async id => {
	const { data: statsData } = await useFetch(
		`/api/statistics/${id}`
	);

	const statStore = useStatsStore();

	if (statsData.value.code === 200) {
		statStore.hydrateStats(statsData.value.data);
		statStore.isFetching = false;
	} else {
		statStore.error = statsData.value.message;
	}
};
