import { default as admintMKYXMmtvkMeta } from "/workspace/pages/admin.vue?macro=true";
import { default as profileUKaL9fvezGMeta } from "/workspace/pages/clients/[client]/profile.vue?macro=true";
import { default as _91uId_932tnyOzxCCaMeta } from "/workspace/pages/clients/[uId].vue?macro=true";
import { default as indexD38M8hTKGPMeta } from "/workspace/pages/clients/index.vue?macro=true";
import { default as confirmx5TWqDxzC1Meta } from "/workspace/pages/confirm.vue?macro=true";
import { default as dashboardKIhr9Z9Xg1Meta } from "/workspace/pages/dashboard.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as loginGeKhs9qf7uMeta } from "/workspace/pages/login.vue?macro=true";
import { default as logoutp3oIBCaMy6Meta } from "/workspace/pages/logout.vue?macro=true";
import { default as _91ipId_9392JLaiF3MyMeta } from "/workspace/pages/posts/[uId]/[ipId].vue?macro=true";
import { default as indexPeNcYHABmsMeta } from "/workspace/pages/posts/index.vue?macro=true";
import { default as newC7UjQMpaNTMeta } from "/workspace/pages/posts/new.vue?macro=true";
import { default as profilePkbik2W1P0Meta } from "/workspace/pages/profile.vue?macro=true";
import { default as settingsp6gTdPz8G5Meta } from "/workspace/pages/settings.vue?macro=true";
import { default as profileavwdVk6xKkMeta } from "/workspace/pages/staff/[employee]/profile.vue?macro=true";
import { default as indexsRGPIg0DwKMeta } from "/workspace/pages/staff/index.vue?macro=true";
import { default as testQmEciF7XchMeta } from "/workspace/pages/test.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: admintMKYXMmtvkMeta || {},
    component: () => import("/workspace/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: "clients-client-profile",
    path: "/clients/:client()/profile",
    meta: profileUKaL9fvezGMeta || {},
    component: () => import("/workspace/pages/clients/[client]/profile.vue").then(m => m.default || m)
  },
  {
    name: "clients-uId",
    path: "/clients/:uId()",
    meta: _91uId_932tnyOzxCCaMeta || {},
    component: () => import("/workspace/pages/clients/[uId].vue").then(m => m.default || m)
  },
  {
    name: "clients",
    path: "/clients",
    meta: indexD38M8hTKGPMeta || {},
    component: () => import("/workspace/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/workspace/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardKIhr9Z9Xg1Meta || {},
    component: () => import("/workspace/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginGeKhs9qf7uMeta || {},
    component: () => import("/workspace/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutp3oIBCaMy6Meta || {},
    component: () => import("/workspace/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "posts-uId-ipId",
    path: "/posts/:uId()/:ipId()",
    meta: _91ipId_9392JLaiF3MyMeta || {},
    component: () => import("/workspace/pages/posts/[uId]/[ipId].vue").then(m => m.default || m)
  },
  {
    name: "posts",
    path: "/posts",
    component: () => import("/workspace/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "posts-new",
    path: "/posts/new",
    meta: newC7UjQMpaNTMeta || {},
    component: () => import("/workspace/pages/posts/new.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilePkbik2W1P0Meta || {},
    component: () => import("/workspace/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/workspace/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "staff-employee-profile",
    path: "/staff/:employee()/profile",
    component: () => import("/workspace/pages/staff/[employee]/profile.vue").then(m => m.default || m)
  },
  {
    name: "staff",
    path: "/staff",
    meta: indexsRGPIg0DwKMeta || {},
    component: () => import("/workspace/pages/staff/index.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/workspace/pages/test.vue").then(m => m.default || m)
  }
]