import {
	useUserStore,
	lineBreaks,
	sanitise,
	POST_STATES,
} from "#imports";

export const usePostStore = defineStore("posts", {
	state: () => ({
		posts: [],
		post: {
			type: "",
			subject: "",
			content: "",

			client: "",
			creator: "",
			assignee: "",
			dateScheduled: "",
		},
		poll: {
			question: "",
			answers: [],
			duration: 0,
		},
		note: {
			content: "",
		},
		error: null,
		isLoading: false,
		isDisabled: false,
	}),
	persist: true,
	getters: {
		getPosts: state => state.posts,
		canUpdate: state =>
			state.post.content.length >= 5 &&
			state.post.content !== "" &&
			state.post.subject.length >= 5 &&
			state.post.subject !== ""
				? true
				: false,
		getCurrentClientsPostListLength: state => {
			const client = state.post.meta.client;

			return state.posts.items.filter(
				post => post.meta && post.meta.client === client
			).length;
		},
		//Check if this post has been accepted by all parties and therefore ready to be scheduled and posted
		isAccepted: state =>
			state.post?.meta?.dates?.dateUserAccepted?.iteration >
				0 &&
			state.post?.meta?.dates?.dateAdminAccepted
				?.iteration > 0 &&
			state.post?.meta?.dates?.datePublished?.iteration == 0
				? true
				: false,
		//CHeck if this post has been posted
		isPosted: state =>
			state.post?.meta?.dates?.datePosted ? true : false,
		//Check if the post has been reassigned
		// isReassigned: state =>
		// 	useUserStore().currentUser.meta.level === 2 &&
		// 	state.post?.meta?.creator !==
		// 		state.post?.meta?.assignee
		// 		? true
		// 		: false,
		// Check if the post is in review (i.e. if the post is currently owned by another user, due to review)
		isSentForReview: state =>
			useUserStore().currentUser.meta.level === 2 &&
			state.post.meta.currentOwner !==
				useUserStore().currentUser.uId
				? true
				: useUserStore().currentUser.meta.level === 3 &&
					  state.post.meta.currentOwner ===
							useUserStore().currentUser.uId &&
					  state.post.meta.creator !==
							useUserStore().currentUser.uId
					? true
					: false,
		//Check if the current user is the same as the currentOwner of a post. Used to determine if the user can take action on the post.
		isCurrentOwner: state =>
			state.post?.meta?.currentOwner ===
			useUserStore().currentUser.uId,
		//Get current status of the post in scheduling journey
		currentStatus: state =>
			state.post?.meta?.currentStatus ?? "new",
		//Get the current post stage. If post status is new, in progress, or in review, stage is Draft Stage. If post status is created, read, or amended, stage is Live Stage. If post status is accepted by user, accepted by admin, or scheduled, stage is Scheduled Stage. If post status is published, declined, or deleted, stage is Archived Stage.
		currentStage: state => {
			return POST_STATES[state.currentStatus].stage;
		},
		currentUpdateCount: state =>
			state.post?.meta?.updateCount,
		formatPostContent: state => {
			//Replace line breaks with HTML line breaks
			const content = lineBreaks(state.post.content);

			//Sanitise content
			const sanitisedContent = sanitise(content);

			return sanitisedContent;
		},
	},
	actions: {
		hydratePosts(posts) {
			delete posts._id;
			this.posts = posts;
		},

		//Used in dialog when creating/amending/scheduling a post
		async activatePost(action) {
			console.log("activatePost action: ", action);

			const userStore = useUserStore();
			const userLevel = userStore.currentUser.meta.level;
			const currentUser = userStore.currentUser.uId;
			const reviewer = this.post.meta.reviewer;
			const creator = this.post.meta.creator;
			const client = this.post.meta.client;
			const postType =
				this.currentStage === "new" ? "new" : "update";

			const { createPost } = usePostCreator();

			//Define strategies as functions
			const acceptStrategy = () => {
				if (userLevel === 1) {
					return createPost({
						postType,
						postStatus: "scheduled",
						currentOwner: reviewer ?? creator,
					});
				}

				if (userLevel === 3) {
					return createPost({
						postType,
						postStatus: "acceptedByAdmin",
						currentOwner: client,
					});
				}

				return null;
			};

			const draftStrategy = () => {
				console.log("draftStrategy");
				console.log("userLevel: ", userLevel);

				if (userLevel === 1) {
					return createPost({
						postType,
						postStatus: "amendedByUser",
						currentOwner: currentUser,
					});
				}

				if (userLevel === 2 || userLevel === 3) {
					return createPost({
						postType,
						postStatus: "inProgress",
						currentOwner: currentUser,
					});
				}

				return null;
			};

			const amendStrategy = () => {
				if (userLevel === 1) {
					return createPost({
						postType,
						postStatus: "amendedByUser",
						currentOwner: creator,
					});
				}

				if (userLevel === 3) {
					return createPost({
						postType,
						postStatus: "amendedByAdmin",
						currentOwner: client,
					});
				}

				return null;
			};

			const sendForReviewStrategy = () => {
				if (userLevel === 1) {
					return null;
				}

				if (userLevel === 2) {
					return createPost({
						postType,
						postStatus: "inReview",
						currentOwner: reviewer,
					});
				}

				return null;
			};

			const sendToCreatorStrategy = () => {
				if (userLevel === 3) {
					return createPost({
						postType,
						postStatus: "inReview",
						currentOwner: creator,
					});
				}

				return null;
			};

			const rescheduleStrategy = () => {
				const isRescheduled = true;

				if (userLevel === 1) {
					return createPost({
						postType,
						postStatus: "acceptedByUser",
						currentOwner: reviewer ?? creator,
						isRescheduled,
					});
				}

				if (userLevel === 2 || userLevel === 3) {
					if (
						this.currentStage === "new" ||
						this.currentStage === "draft"
					) {
						return createPost({
							postType,
							postStatus: "inProgress",
							currentOwner: currentUser,
							isRescheduled,
						});
					} else {
						return createPost({
							postType,
							postStatus: "amendedByAdmin",
							currentOwner: client,
							isRescheduled,
						});
					}
				}

				return null;
			};

			const postNowStrategy = () => {
				if (userLevel === 1 || userLevel === 2) {
					return null;
				}

				if (userLevel === 3) {
					return createPost({
						postType,
						postStatus: "published",
						currentOwner: null,
					});
				}

				return null;
			};

			const declineStrategy = () => {
				return createPost({
					postType: "update",
					postStatus: "declined",
					currentOwner: null,
				});
			};

			const deleteStrategy = () => {
				return createPost({
					postType: "delete",
					postStatus: "deleted",
					currentOwner: null,
				});
			};

			const reviveStrategy = () => {
				return null;
			};

			//Strategy mapping
			const strategyMap = {
				accept: acceptStrategy,
				draft: draftStrategy,
				amend: amendStrategy,
				review: sendForReviewStrategy,
				return: sendToCreatorStrategy,
				reschedule: rescheduleStrategy,
				postNow: postNowStrategy,
				decline: declineStrategy,
				delete: deleteStrategy,
				revive: reviveStrategy,
			};

			//Factory function to get the appropriate strategy
			const getStrategy = action => {
				const strategy = strategyMap[action];

				if (!strategy) {
					throw new Error("Strategy not found");
				}

				return strategy;
			};

			//Execute relevant function
			const strategyFunction = getStrategy(action);

			const result = strategyFunction();

			console.log("result: ", result);

			return result;
		},

		//Used to provide initial state for new post when navigating to /posts/new
		async createNewPost() {
			this.post = {
				type: "",
				subject: "",
				content: "",
				dateScheduled: "",
				date: "",
				time: "",
				meta: {
					files: [],
					poll: {},
					currentStatus: "new",
					currentOwner: useUserStore().currentUser.uId,
					client: "",
					creator: "",
					assignee: "",
					reviewer: "",
					updateCount: 0,
					notes: [],
				},
			};
		},

		//If dateScheduled date and time are not empty, return ISO string
		async createDateScheduled(postType, postStatus) {
			if (this.post.date && this.post.time) {
				//Convert date to ISO string
				const date = new Date(
					`${this.post.date}T${this.post.time}`
				);

				this.post.dateScheduled = date.toISOString();

				const { createPost } = usePostCreator();
				return await createPost({
					postType,
					postStatus,
					isRescheduled: true,
				});
			} else {
				//TODO: Return error that the date and time need filling out in the dialog (toast?)
				return null;
			}
		},

		//Add file(s) to post
		addFiles(files) {
			//Create file array
			const fileArray = [];

			//Loop through files and add name, type, and size to fileArray
			//FIXME: Location isn't confirmed yet as will be using DO Spaces for storage. Need to alter this to use the correct location.
			for (let i = 0; i < files.length; i++) {
				fileArray.push({
					name: files[i].name,
					type: files[i].type,
					size: files[i].size,
				});
			}

			//Add fileArray to post
			this.post.meta.files = fileArray;
		},

		//Add poll to a post
		addPoll(payload) {
			this.poll = {
				...payload,
			};

			this.post.meta.poll = {
				...payload,
			};
		},

		//Add note to a post
		addNote() {
			const userStore = useUserStore();
			const authorId = userStore.currentUser.uId;
			const dateWritten = new Date();

			const note = {
				authorId,
				authorName: userStore.currentUser.firstName,
				content: this.note.content,
				dateWritten,
			};

			const notes = this.post.meta.notes;
			notes.push(note);

			//Reset note content field
			this.note.content = "";
		},

		//Reset files
		resetFiles() {
			this.post.meta.files = [];
		},

		//Hide post
		hidePost(ipId) {
			const post = this.posts.items.find(
				post => post.ipId === ipId
			);

			if (post) {
				post.meta.hidden = true;
			}
		},

		//Add post client and creator details to post (ids from select menu in post scheduler)
		addUserDetails(userType, id) {
			switch (userType) {
				case "client":
					this.post.meta.client = id;
					break;
				case "creator":
					this.post.meta.creator = id;
					break;
				case "assignee":
					this.post.meta.assignee = id;
					break;
				case "reviewer":
					this.post.meta.reviewer = id;
					break;
			}
		},
	},
});
