import { useUserStore } from "@/stores/user";

export const useUser = () => {
	const store = useUserStore();

	const {
		userType,
		userLevel,
		isClient,
		isSignedIn,
		isAuthenticated,
		userName,
		firstName,
		currentUser,
		getCurrentUser,
		getAllUsers,
	} = storeToRefs(store);

	return {
		userType,
		userLevel,
		currentUser,
		isClient,
		isSignedIn,
		isAuthenticated,
		userName,
		firstName,
		getCurrentUser,
		getAllUsers,
		logIn: store.logIn,
		hydrateUserDetails: store.hydrateUserDetails,
		checkUserExists: store.checkUserExists,
		isUserAClient: store.isUserAClient,
	};
};
