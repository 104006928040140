import { POST_STATES } from "@/utils/constants";
import { useNotificationStore } from "@/stores/notifications";

export default () => {
	const apiCall = "notifications";
	const { sendEmail } = useEmail();
	const notificationStore = useNotificationStore();

	//Get the client and creator names and emails
	const matchRecipients = notificationSettings => {
		const { getClientsAndEmails, getAdminsAndEmails } =
			useAdmin();
		const matchedClient = getClientsAndEmails.find(
			client => client.uId === notificationSettings.client
		);
		const matchedCreator = getAdminsAndEmails.find(
			admin => admin.uId === notificationSettings.creator
		);
		return { matchedClient, matchedCreator };
	};

	//Build the content object for the email
	const buildContentObject = async notificationSettings => {
		//Get basic elements of the email content
		const { stage: status } = notificationSettings;

		//Pull it all together in the POST_STATES object
		const postStates = JSON.parse(
			JSON.stringify(POST_STATES[status])
		);

		//If creator and assignee are the same, then remove the assignee data from postStates
		if (notificationStore.isCreatorAndAssigneeSame) {
			postStates.email.assignee = null;
			postStates.notification.assignee = null;
		}

		return postStates;
	};

	//Called when creating a new notification
	const setNotification = async (
		content,
		notificationSettings
	) => {
		console.log("content: ", content);
		console.log(
			"notificationSettings: ",
			notificationSettings
		);

		const ids = {
			client:
				notificationStore.recipientDetails?.client?.id,
			creator:
				notificationStore.recipientDetails?.creator?.id,
			reviewer:
				notificationStore.recipientDetails?.reviewer?.id ??
				null,
			assignee: notificationStore.isCreatorAndAssigneeSame
				? null
				: notificationStore.recipientDetails?.assignee?.id,
		};

		console.log("ids: ", ids);

		//1. Create notification content
		const notificationContent = {
			//Get subject and content of notification from POST_STATES by matching status and retrieving notificationDetails.subject and notificationDetails.content
			details: {
				notification: content.notification,
				email: content.email,
			},
			meta: {
				creator: ids.creator,
				client: ids.client,
				assignee: ids.assignee,
				reviewer: ids.reviewer,
				link: notificationSettings.ipId,
				isRead: false,
			},
		};

		notificationStore.currentNotification =
			notificationContent;
		//2. Call API to create notification
		try {
			const response = await usePOST(
				notificationContent,
				apiCall,
				"new"
			);

			console.log("response: ", response);
			console.log("code: ", response.value.code);

			if (response.value.code === 200) {
				//Show success message
				return true;
			}
		} catch (error) {
			//Show error message
			return error;
		}
	};

	//Called when updating an existing notification
	const amendNotification = async nId => {
		const notificationContent = {
			isRead: true,
			nId,
		};
		try {
			//Call API to update existing notification
			const response = await usePATCH(
				notificationContent,
				apiCall,
				"update"
			);
			if (response.value.code === 200) {
				//Show success message
				return true;
			}
		} catch (error) {
			//Show error message
			return error;
		}
	};

	//Called when deleting an existing notification
	const deleteNotification = async () => {};

	//STARTING POINT: Called when creating, updating, or deleting a notification, which calls the appropriate function based on the action. This is only needed if an email is involved. If Notification only, use the appropriate function directly.
	const makeNotification = async notificationSettings => {
		console.log(
			"notificationSettings",
			notificationSettings.action
		);

		//Create notification that will go to each recipient, based on whether this is a new notification (setNotification), an update to an existing notification (amendNotification), or a deletion of an existing notification (deleteNotification)
		const doNotification = {
			new: async () => {
				const content = await buildContentObject(
					notificationSettings
				);

				await setNotification(
					content,
					notificationSettings
				);

				if (notificationStore.isCreatorAndAssigneeSame) {
					//Remove assignee from recipientDetails
					notificationStore.recipientDetails.assignee =
						null;
				}

				//Create email notification to send to both sender and recipient
				await sendEmail(
					notificationStore.recipientDetails,
					notificationStore.currentNotification
				);
			},
			review: async () => {
				console.log("Reviewing notification for creator");
			},
			// NOTE: Need to check if clicking 'Amend' in post scheduler uses update function or not as emails don't appear to be being sent.
			update: async () => {
				// const recipients = matchRecipients(
				// 	notificationSettings
				// );
				const content = await buildContentObject(
					notificationSettings
				);
				await amendNotification(notificationSettings.nId),
					await sendEmail(
						notificationSettings.ipId,
						content,
						recipients
					);
			},
			delete: async () => await deleteNotification(),
			error: () => {
				return "Error: Action not found";
			},
		};
		//Call the appropriate notification function, or return an error message if the function doesn't exist
		return (
			(await doNotification[
				notificationSettings.action
			]()) || doNotification.error()
		);
	};

	//Return the makeNotification function
	return { makeNotification, amendNotification };
};
