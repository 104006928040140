export default defineNuxtRouteMiddleware(
	async (to, from) => {
		//Only run client side
		if (process.server) return;

		if (
			to.path === "/" ||
			to.path === "/login" ||
			to.path === "/confirm" ||
			to.path === "/test" ||
			to.path === "/admin"
		) {
			return;
		}

		//Hydrate user
		//NOTE: At this point, the user is already authenticated and has a valid session. Now all we do is check if the user is a client or admin, and then fetch the remaining data needed for hydration.

		const isUserFullyAuthenticated = ref(false);

		const {
			currentUser,
			isClient,
			isSignedIn,
			isAuthenticated,
		} = useUser();

		if (
			currentUser.value &&
			isAuthenticated.value &&
			isSignedIn.value
		) {
			console.log(
				"User exists, is authenticated, and signed in"
			);
			isUserFullyAuthenticated.value = true;
		}

		if (!isUserFullyAuthenticated.value) {
			//Check if localStorage has a uId
			const localUId = localStorage.getItem("uId") ?? null;

			//TODO: Currently for admin only, but this could quite easily be built out for the client too, but that would mean retaining the data in the database for the client, which may not be feasible given LinkedIn's API restrictions.
			if (localUId) {
				//Only need to hydrate user details if they are not already authenticated. They may already be authenticated if they have a valid session

				const { hydrateUserDetails } = useUser();
				const response = await hydrateUserDetails(
					localUId,
					false
				);

				if (!response) {
					return navigateTo({
						path: "/login",
						query: {
							error:
								"You are not a valid user. Please log in again using the correct details.",
							code: "KSERR-008",
						},
					});
				}
			}

			if (isSignedIn.value) {
				const uId = currentUser.value.uId;
				const isHydrationComplete = ref(false);

				//Fetch remaining data needed for hydration
				const fetchDataForUserType = async userType => {
					try {
						await useNotifications(uId);
						await usePosts(uId, userType, false);
						if (userType === "client") {
							await useStats(uId);
						} else {
							await useClients();
							await useStaff();
							await usePosts("all", userType, true);
						}
						return true;
					} catch (error) {
						console.error(error);
						return false;
					}
				};

				//Check if user is admin
				if (isClient.value) {
					//Client
					isHydrationComplete.value =
						await fetchDataForUserType("client");
				} else if (!isClient.value) {
					//Admin
					isHydrationComplete.value =
						await fetchDataForUserType("admin");
				} else {
					return navigateTo("/login");
				}

				if (isSignedIn.value && isHydrationComplete.value) {
					isAuthenticated.value = true;

					return;
				} else {
					isSignedIn.value = false;
					return navigateTo({
						path: "/login",
						query: {
							error:
								"Your log in details are incorrect. Please try again.",
							code: "KSERR-003",
						},
					});
				}
				return;
			} else {
				return navigateTo({
					path: "/login",
					query: {
						error:
							"There was an error finding your account details. Please try again. If the problem persists, please contact support.",
						code: "KSERR-005",
					},
				});
			}
		} else {
			if (to.path === "/dashboard") {
				console.log(
					"hydrating notifications and posts only for authenticated user"
				);

				//Rehydrate notifications and posts only
				const uId = currentUser.value.uId;
				const userType = currentUser.value.meta.type;

				await useNotifications(uId);
				await usePosts(uId, userType, false);
			}
		}
	}
);
