//Convert string to sentence case
export const sentenceCase = str => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

//Convert string to title case
export const titleCase = string => {
	const stringArray = string.split(" ");

	const newArray = stringArray.map(word => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});

	return newArray.join(" ");
};

//Convert string to lower case
export const lowerCase = str => {
	return str.toLowerCase();
};

//Convert string to upper case
export const upperCase = str => {
	return str.toUpperCase();
};

//Truncate string to specified length. If string is longer than length, add "..." to end of string
export const truncate = (str, length) => {
	return str.length > length
		? str.substring(0, length).trim() + "..."
		: str;
};

//Add spaces to camel case string
export const camelCaseToSpaces = str => {
	return str.replace(/([A-Z])/g, " $1");
};

//Join two strings together with a space
export const joinWithSpace = (str1, str2) => {
	return `${str1} ${str2}`;
};

//Split dateTime string into date and time
export const splitDateTime = dateTime => {
	const date = dateTime.split("T")[0];
	const time = dateTime.split("T")[1].split(".")[0];

	return { date, time };
};

//Format date as YYYY-MM-DD
export const formatDate = dateStr => {
	const date = new Date(dateStr);
	const year = date.getFullYear();
	const month = (date.getMonth() + 1)
		.toString()
		.padStart(2, "0"); // add 1 because months are 0-indexed
	const day = date.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`;
};

//Sanitise string
export const sanitise = str => {
	return str
		.replace(/<(?!br\s*\/?)[^>]+>/g, "")
		.replace(/[^a-zA-Z0-9<>/ ]/g, "");
};

//Acknowledge line breaks in string
export const lineBreaks = str => {
	return str.replace(/\n/g, "<br />");
};

//Vite check env MODE
export let port = null;

import.meta.env.MODE === "dev"
	? (port = 5050)
	: (port = 8080);

//Get asset from URL
export const getAssetFromUrl = url => {
	const urlArray = url.split("/");
	return urlArray[urlArray.length - 1];
};

//Get user Id from Url: "posts/1/2 => 1"
export const getUserIdFromUrl = url => {
	const urlArray = url.split("/");
	return urlArray[urlArray.length - 2];
};

//Generic try/block helper wrapper
export const tryCatch = async (fn, ...args) => {
	try {
		return await fn(...args);
	} catch (error) {
		console.error(error);
	}
};
