//Consts for userLevels
export const LINKED_VIS = "PUBLIC";

export const USER_LEVELS = {
	1: {
		level: 1,
		name: "User",
		restricted: {
			paths: ["/posts/new"],
			postStates: ["new", "inProgress", "inReview"],
		},
		actions: {
			post: {
				new: null,
				draft: null,
				live: [
					"accept",
					"draft",
					"amend",
					"reschedule",
					"decline",
				],
				approval: [
					"accept",
					"draft",
					"amend",
					"reschedule",
					"decline",
				],
				scheduling: ["reschedule", "decline"],
			},
		},
	},
	2: {
		level: 2,
		name: "Creator",
		actions: {
			post: {
				new: ["draft", "sendForReview", "reschedule"],
				draft: [
					"draft",
					"sendForReview",
					"reschedule",
					"decline",
				],
				live: ["draft", "sendForReview", "reschedule"],
				approval: ["sendForReview", "reschedule"],
				scheduling: ["sendForReview", "reschedule"],
			},
		},
	},
	3: {
		level: 3,
		name: "Admin",
		actions: {
			post: {
				new: ["accept", "draft", "reschedule"],
				draft: [
					"accept",
					"draft",
					"returnToCreator",
					"reschedule",
					"decline",
				],
				live: [
					"accept",
					"draft",
					"amend",
					"returnToCreator",
					"reschedule",
					"postNow",
					"decline",
				],
				approval: [
					"accept",
					"draft",
					"amend",
					"returnToCreator",
					"reschedule",
					"decline",
				],
				scheduling: [
					"draft",
					"amend",
					"returnToCreator",
					"reschedule",
					"postNow",
					"decline",
				],
			},
		},
	},
};

export const ACCOUNT_STATUS = [
	"active",
	"inactive",
	"onboarding",
	"suspended",
	"deleted",
];

export const BUTTON_TYPES = [
	{
		name: "accept",
		icon: {
			enabled: "/icons/post-accept.svg",
			disabled: "/icons/post-accept-disabled.svg",
		},
		colour: {
			base: "bg-yellow-600/40 hover:bg-yellow-600/50",
			border: "border-too-gold",
		},
		isDisabled: true,
		isVisible: false,
		modal: "accept",
	},
	{
		name: "draft",
		icon: {
			enabled: "/icons/post-draft.svg",
			disabled: "/icons/post-draft-disabled.svg",
		},
		colour: {
			base: "bg-yellow-700/50 hover:bg-yellow-600/70",
			border: "border-slate-500",
		},
		isDisabled: true,
		isVisible: false,
		isAmendable: true,
		modal: "draft",
	},
	{
		name: "amend",
		icon: {
			enabled: "/icons/post-amend.svg",
			disabled: "/icons/post-amend-disabled.svg",
		},
		colour: {
			base: "bg-yellow-700/30 hover:bg-yellow-700/40",
			border: "border-golden-brown",
		},
		isDisabled: true,
		isVisible: false,
		isAmendable: true,
		modal: "amend",
	},
	{
		name: "sendForReview",
		icon: {
			enabled: "/icons/post-review.svg",
			disabled: "/icons/post-review-disabled.svg",
		},
		colour: {
			base: "bg-blue-500/30 hover:bg-blue-500/40",
			border: "border-slate-500",
		},
		isDisabled: true,
		isVisible: false,
		isAmendable: true,
		modal: "review",
	},
	{
		name: "returnToCreator",
		icon: {
			enabled: "/icons/post-review.svg",
			disabled: "/icons/post-review-disabled.svg",
		},
		colour: {
			base: "bg-blue-500/30 hover:bg-blue-500/40",
			border: "border-slate-500",
		},
		isDisabled: false,
		isVisible: false,
		isAmendable: true,
		modal: "return",
	},
	{
		name: "reschedule",
		icon: {
			enabled: "/icons/post-reschedule.svg",
			disabled: "/icons/post-reschedule-disabled.svg",
		},
		colour: {
			base: "bg-commandes/30 hover:bg-commandes/40",
			border: "border-new-navy",
		},
		isDisabled: true,
		isVisible: false,
		modal: "reschedule",
	},
	{
		name: "postNow",
		icon: {
			enabled: "/icons/post-now.svg",
			disabled: "/icons/post-now-disabled.svg",
		},
		colour: {
			base: "bg-voir-blue/30 hover:bg-voir-blue/40",
			border: "border-voir-blue",
		},
		isDisabled: true,
		isVisible: false,
		modal: "postNow",
	},
	{
		name: "decline",
		icon: {
			enabled: "/icons/post-decline.svg",
			disabled: "/icons/post-decline-disabled.svg",
		},
		colour: {
			base: "bg-sheffield-grey/30 hover:bg-sheffield-grey/40",
			border: "border-slate-500",
		},
		isDisabled: true,
		isVisible: false,
		modal: "decline",
	},
	{
		name: "delete",
		icon: {
			enabled: "/icons/post-decline.svg",
			disbaled: "/icons/post-decline-disabled.svg",
		},
		colour: {
			base: "slate-500",
			border: "border-slate-500",
		},
		isDisabled: true,
		isVisible: false,
		modal: "",
	},
	{
		name: "revive",
		icon: {
			enabled: "/icons/post-revive.svg",
			disabled: "/icons/post-revive-disabled.svg",
		},
		colour: {
			base: "bg-slate-500",
			border: "border-slate-500",
		},
		isDisabled: true,
		isVisible: false,
		modal: "revive",
	},
];

export const POST_STAGES = {
	new: {
		name: "new",
		order: 1,
		tooltip: "Post is newly created.",
	},
	draft: {
		name: "draft",
		order: 2,
		tooltip: "Post is in draft.",
	},
	live: {
		name: "live",
		order: 3,
		tooltip: "Post is now awaiting approval.",
	},
	approval: {
		name: "approval",
		order: 4,
		tooltip: "Post is currently being approved.",
	},
	scheduling: {
		name: "scheduling",
		order: 5,
		tooltip: "Post is scheduled for publication.",
	},
	published: {
		name: "published",
		order: 6,
		tooltip: "Post is published.",
	},
	archived: {
		name: "archived",
		order: 7,
		tooltip: "Post is archived.",
	},
	deleted: {
		name: "deleted",
		order: 8,
		tooltip: "Post is deleted.",
	},
};

//Consts for postStates
//FIXME: POST_STATES needs to be dropped so that the createPostStates function can be used instead. It needs to be re-referenced throughout the codebase where it is used.
export const POST_STATES = {
	new: {
		name: "new",
		rawName: "new",
		dateName: "dateNew",
		tooltip: "Post created",
		stage: POST_STAGES.new.name,
		notification: {
			client: null,
			creator: {
				subject: "You created a new post for",
				content: "Your new post is now created.",
			},
			assignee: {
				subject: "You have been assigned a new post.",
				content:
					"Please review it and make any additional comments as necessary.",
			},
			reviewer: null,
			id: "NOT_NEW_POST",
		},
		email: {
			client: null,
			creator: {
				subject: "You created a new post for",
				content:
					"Your new post entitled is now created. The post reads as follows:",
			},
			assignee: {
				subject: "You have been assigned a new post.",
				content:
					"Please review it and make any additional comments as necessary.",
			},
			reviewer: null,
			id: "EMAIL_NEW_POST",
		},
	},
	inProgress: {
		name: "in progress",
		rawName: "inProgress",
		dateName: "dateProgress",
		tooltip: "In progress",
		stage: POST_STAGES.draft.name,
		notification: {
			client: null,
			creator: {
				subject: "You progressed your post to draft",
				content:
					"This post is in draft. It can be amended or reviewed before sharing with the client.",
			},
			assignee: {
				subject: "You have been assigned a new post.",
				content:
					"Please review it and make any additional comments as necessary.",
			},
			reviewer: null,
			id: "NOT_IN_PROGRESS",
		},
		email: {
			client: null,
			creator: {
				subject: "You progressed your post to draft",
				content:
					"This post is in draft. It can be amended or reviewed before sharing with the client.",
			},
			assignee: {
				subject: "You have been assigned a new post.",
				content:
					"Please review it and make any additional comments as necessary.",
			},
			reviewer: null,
			id: "EMAIL_IN_PROGRESS",
		},
	},
	inReview: {
		name: "in review",
		rawName: "inReview",
		dateName: "dateReview",
		tooltip: "In review by admin",
		stage: POST_STAGES.draft.name,
		notification: {
			client: null,
			creator: {
				subject: "You have sent your post for review.",
				content:
					"You will be notified when it has been amended or approved.",
			},
			assignee: {
				subject: "You have been assigned a new post.",
				content:
					"Please review it and make any additional comments as necessary.",
			},
			reviewer: {
				subject: "You have a new post to review.",
				content:
					"A member of your team has sent you a post to review. Please review and either send back for further work, or approve to send to the client.",
			},
			id: "NOT_IN_REVIEW",
		},
		email: {
			client: null,
			creator: {
				subject: "Your post is now being reviewed.",
				content:
					"You will be notified when it has been amended or approved.",
			},
			assignee: {
				subject: "You have been assigned a new post.",
				content:
					"Please review it and make any additional comments as necessary.",
			},
			reviewer: {
				subject: "You have a new post to review.",
				content:
					"A member of your team has sent you a post to review. Please review and either send back for further work, or approve to send to the client.",
			},
			id: "EMAIL_IN_REVIEW",
		},
	},
	created: {
		name: "live",
		rawName: "created",
		dateName: "dateCreated",
		tooltip: "Available for review",
		stage: POST_STAGES.live.name,
		notification: {
			client: {
				subject: "A new post has been created for you.",
				content:
					"Please review it and make any amendments if necessary. If you're happy with it, please accept the post to progress to scheduling.",
			},
			creator: {
				subject:
					"Your post has been sent to the client for review.",
				content:
					"The client can now review it and either amend or accept it.",
			},
			assignee: {
				subject:
					"Your post has been sent to the client for review.",
				content:
					"The client can now review it and either amend or accept it.",
			},
			reviewer: null,
			id: "NOT_CREATED",
		},
		email: {
			client: {
				subject: "You have a new post.",
				content:
					"Please review it and make any amendments if necessary. If you're happy with it, please accept the post to that it can be scheduled for posting.",
			},
			creator: {
				subject: "Your post is now live.",
				content:
					"The client can now review it and either amend or accept it.",
			},
			assignee: {
				subject: "Your post is now live.",
				content:
					"The client can now review it and either amend or accept it.",
			},
			reviewer: null,
			id: "EMAIL_CREATED",
		},
	},
	read: {
		name: "read",
		rawName: "read",
		dateName: "dateRead",
		tooltip: "Read by client",
		stage: POST_STAGES.live.name,
		notification: {
			client: {
				subject: "Are you ready to accept this post?",
				content:
					"Please accept this post if you're happy with it. If not, please make any amendments and resubmit it for review.",
			},
			creator: {
				subject: "Your post has been read by the client.",
				content:
					"The client is now ready to accept or decline the post.",
			},
			assignee: null,
			reviewer: null,
			id: "NOT_READ",
		},
		email: {
			client: {
				subject: "Are you ready to accept this post?",
				content:
					"Please accept this post if you're happy with it. If not, please make any amendments and resubmit it for review.",
			},
			creator: {
				subject: "Your post has been read by the client.",
				content:
					"The client is now ready to accept or decline the post.",
			},
			assignee: null,
			reviewer: null,
			id: "EMAIL_READ",
		},
	},
	amendedByAdmin: {
		name: "amended by creator",
		rawName: "amendedByAdmin",
		dateName: "dateAdminAmended",
		tooltip: "Amended by admin",
		stage: POST_STAGES.live.name,
		notification: {
			client: null,
			creator: {
				subject: "You have amended this post.",
				content:
					"This post will now be sent to your reviewer.",
			},
			assignee: null,
			reviewer: null,
			id: "NOT_AMENDED_BY_ADMIN",
		},
		email: {
			client: null,
			creator: {
				subject: "You have amended this post.",
				content:
					"Thist post will now be sent to your reviewer.",
			},
			assignee: null,
			reviewer: null,
			id: "EMAIL_AMENDED_BY_ADMIN",
		},
	},
	amendedByUser: {
		name: "amended by client",
		rawName: "amendedByUsee",
		dateName: "dateUserAmended",
		tooltip: "Amended",
		stage: POST_STAGES.draft.name,
		notification: {
			client: {
				subject: "You have amended your post.",
				content:
					"This post will be sent for review by the team.",
			},
			creator: {
				subject: "Your client has amended this post.",
				content:
					"Please review the amendments. You can accept them or make further amendments yourself.",
			},
			assignee: null,
			reviewer: null,
			id: "NOT_AMENDED_BY_USER",
		},
		email: {
			client: {
				subject: "You have amended your post.",
				content:
					"This post will be sent for review by the team.",
			},
			creator: {
				subject: "Your client has amended this post.",
				content:
					"Please review the amendments. You can accept them or make further amendments yourself.",
			},
			assignee: null,
			reviewer: null,
			id: "EMAIL_AMENDED_BY_USER",
		},
	},
	declined: {
		name: "declined",
		rawName: "declined",
		dateName: "dateDeclined",
		tooltip: "Declined",
		stage: POST_STAGES.archived.name,
		notification: {
			client: {
				subject: "Your post has been declined.",
				content:
					"You may amend it and resubmit it for review. Alternatively it can be deleted.",
			},
			creator: {
				subject: "This post has been declined.",
				content:
					"You may amend it and resubmit it for review. Alternatively it can be deleted.",
			},
			assignee: null,
			reviewer: null,
			id: "NOT_DECLINED",
		},
		email: {
			client: {
				subject: "Your post has been declined.",
				content:
					"You may amend it and resubmit it for review. Alternatively it can be deleted.",
			},
			creator: {
				subject: "This post has been declined.",
				content:
					"You may amend it and resubmit it for review. Alternatively it can be deleted.",
			},
			assignee: null,
			reviewer: null,
			id: "EMAIL_DECLINED",
		},
	},
	acceptedByUser: {
		name: "accepted by client",
		rawName: "acceptedByUser",
		dateName: "dateUserAccepted",
		tooltip: "Approved by client",
		stage: POST_STAGES.approval.name,
		notification: {
			client: {
				subject: "You accepted this post.",
				content: "It is now scheduled for publication.",
			},
			creator: {
				subject:
					"Your post has been accepted by the client.",
				content: "It is now scheduled for publication.",
			},
			assignee: null,
			reviewer: null,
			id: "NOT_ACCEPTED_BY_USER",
		},
		email: {
			client: {
				subject: "You accepted this post.",
				content: "It is now scheduled for publication.",
			},
			creator: {
				subject:
					"Your post has been accepted by the client.",
				content: "It is now scheduled for publication.",
			},
			assignee: null,
			reviewer: null,
			id: "EMAIL_ACCEPTED_BY_USER",
		},
	},
	acceptedByAdmin: {
		name: "accepted by creator",
		rawName: "acceptedByAdmin",
		dateName: "dateAdminAccepted",
		tooltip: "Approved by admin",
		stage: POST_STAGES.approval.name,
		notification: {
			client: null,
			creator: {
				subject:
					"Your changes have been accepted by your manager.",
				content:
					"Further changes can be made by clicking Draft.",
			},
			assignee: null,
			reviewer: null,
			id: "NOT_ACCEPTED_BY_ADMIN",
		},
		email: {
			client: null,
			creator: {
				subject:
					"Amendments to your post have been accepted.",
				content:
					"Your new post has now been approved by your manager. The post reads as follows:",
			},
			assignee: null,
			reviewer: null,
			id: "EMAIL_ACCEPTED_BY_ADMIN",
		},
	},
	scheduled: {
		name: "scheduled",
		rawName: "scheduled",
		dateName: "dateScheduled",
		tooltip: "Scheduled for publication",
		stage: POST_STAGES.scheduling.name,
		notification: {
			client: {
				subject: "This post is scheduled for publication.",
				content:
					"It will be published on the date and time specified.",
			},
			creator: {
				subject: "This post is scheduled for publication.",
				content:
					"It will be published on the date and time specified.",
			},
			assignee: null,
			reviewer: null,
			id: "NOT_SCHEDULED",
		},
		email: {
			client: {
				subject: "This post is scheduled for publication.",
				content:
					"It will be published on the date and time specified.",
			},
			creator: {
				subject: "This post is scheduled for publication.",
				content:
					"It will be published on the date and time specified.",
			},
			assignee: null,
			reviewer: null,
			id: "EMAIL_SCHEDULED",
		},
	},
	published: {
		name: "published",
		rawName: "published",
		dateName: "datePublished",
		tooltip: "Published",
		stage: POST_STAGES.published.name,
		notification: {
			client: {
				subject: "Post is now live!",
				content: "This post is now live on LinkedIn.",
			},
			creator: {
				subject: "Post is now live!.",
				content: "This post is now live on LinkedIn.",
			},
			assignee: null,
			reviewer: null,
			id: "NOT_PUBLISHED",
		},
		email: {
			client: {
				subject: "Post is now live!",
				content: "This post is now live on LinkedIn.",
			},
			creator: {
				subject: "Post is now live!.",
				content: "This post is now live on LinkedIn.",
			},
			assignee: null,
			reviewer: null,
			id: "EMAIL_PUBLISHED",
		},
	},
	deleted: {
		name: "deleted",
		rawName: "deleted",
		dateName: "dateDeleted",
		tooltip: "Permanently deleted",
		stage: POST_STAGES.deleted.name,
		notification: {
			client: null,
			creator: {
				subject: "This post has been deleted.",
				content:
					"It has been permanently deleted and cannot be revived.",
			},
			assignee: null,
			reviewer: null,
			id: "NOT_DELETED",
		},
		email: {
			client: null,
			creator: {
				subject: "This post has been deleted.",
				content:
					"It has been permanently deleted and cannot be revived.",
			},
			assignee: null,
			reviewer: null,
			id: "EMAIL_DELETED",
		},
	},
	revived: {
		name: "revived",
		rawName: "revived",
		dateName: "dateRevived",
		tooltip: "Revived",
		stage: POST_STAGES.draft.name,
		notification: {
			client: null,
			creator: {
				subject: "This post has been revived.",
				content: "It is now available for review.",
			},
			assignee: null,
			reviewer: null,
			id: "NOT_REVIVED",
		},
		email: {
			client: null,
			creator: {
				subject: "This post has been revived.",
				content: "It is now available for review.",
			},
			assignee: null,
			reviewer: null,
			id: "EMAIL_REVIVED",
		},
	},
};

//Consts for postTypes
export const POST_TYPES = [
	{
		type: "text",
		isAssetFormat: false,
	},
	{
		type: "document",
		isAssetFormat: true,
	},
	{
		type: "image",
		isAssetFormat: true,
	},
	{
		type: "video",
		isAssetFormat: true,
	},
];

//Consts for dates and times
export const TODAY = new Date().toISOString().split("T")[0];

export const SCHEDULER = [
	"00:00",
	"00:30",
	"01:00",
	"01:30",
	"02:00",
	"02:30",
	"03:00",
	"03:30",
	"04:00",
	"04:30",
	"05:00",
	"05:30",
	"06:00",
	"06:30",
	"07:00",
	"07:30",
	"08:00",
	"08:30",
	"09:00",
	"09:30",
	"10:00",
	"10:30",
	"11:00",
	"11:30",
	"12:00",
	"12:30",
	"13:00",
	"13:30",
	"14:00",
	"14:30",
	"15:00",
	"15:30",
	"16:00",
	"16:30",
	"17:00",
	"17:30",
	"18:00",
	"18:30",
	"19:00",
	"19:30",
	"20:00",
	"20:30",
	"21:00",
	"21:30",
	"22:00",
	"22:30",
	"23:00",
	"23:30",
];

//Poll durations
export const POLL_DURATIONS = [
	{ name: "1 day", value: 1, available: true },
	{ name: "3 days", value: 3, available: true },
	{ name: "10 days", value: 10, available: true },
	{ name: "14 days", value: 14, available: true },
];

//Const URLs
export const URL_TYPE = {
	newPost: "/posts/new",
	newClient: "/client/new",
};

//Consts for table related to various groups
export const TABLE_LAYOUT = {
	clients: ["name", "company", "role", "status"],
	staff: ["name", "role", "status"],
	posts: ["subject", "status", "date"],
};

//Consts for modal data
export const MODAL_DATA = {
	onboarding: {
		clients: {
			title: "Hey <name>. So glad you could join us.",
			content: "Fancy having a look around?",
			actions: {
				primary: {
					label: "Yes, please!",
					action: null,
				},
				secondary: {
					label: "Nah, I got it.",
					action: null,
				},
			},
		},
		staff: {
			title: "Hey <name>. Great to bring you on board.",
			content: "Shall we get you set up?",
		},
	},
};
