import { useAdminStore } from "@/stores/admin";

export default async () => {
	const { data: clientsData } = await useFetch(
		"/api/users/clients"
	);

	const adminStore = useAdminStore();

	if (clientsData.value.code === 200) {
		adminStore.hydrateClients(clientsData.value.data);
		return true;
	} else {
		adminStore.error = clientsData.value.message;
		return false;
	}
};
