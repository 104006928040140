import { defineStore } from "pinia";
import { USER_LEVELS } from "@/utils/constants";
import { set } from "mongoose";

// FIXME: uIds need obscuring in localStorage and hashing
export const useUserStore = defineStore("userMain", {
	state: () => ({
		currentUser: {},
		//error: null,
		//isLoading: false,
		isSignedIn: false,
		isAuthenticated: false,
		users: [],
	}),
	getters: {
		getCurrentUser(state) {
			return state.currentUser;
		},
		getAllUsers(state) {
			return state.users;
		},
		firstName(state) {
			return state.currentUser.firstName;
		},
		userName(state) {
			return `${state.currentUser.firstName} ${state.currentUser.lastName}`;
		},
		userLevel(state) {
			return state.currentUser.meta.level;
		},
		userType(state) {
			//Check user levels for the level of the user and retrieve the name.
			return USER_LEVELS[state.userLevel]?.name;
		},
		isClient(state) {
			//Null is important here as this would be default to 'admin' if not set, which would reveal the admin dashboard to the client potentially.
			if (state.currentUser?.meta?.type === "client") {
				return true;
			} else if (
				state.currentUser?.meta?.type === "admin"
			) {
				return false;
			} else return null;
		},
		isFirstTime(state) {
			return state.currentUser.meta.isFirstTime;
		},
	},
	actions: {
		// TODO: Needs updating to follow format of posts and clients.
		// NOTE: Log in via email and password is strictly only for admin users. Checks are completed to ensure that this is the case before proceeding.
		async logIn(email, password) {
			// Validate user
			try {
				const { data } = await useFetch("/api/login", {
					method: "POST",
					body: {
						email,
						password,
					},
					server: false,
				});

				if (data.value.code === 500) {
					console.error("Server error");
					return {
						error: data.value.message,
						code: data.value.code,
					};
				}

				if (data.value.code === 401) {
					return {
						error: data.value.message,
						code: data.value.code,
					};
				}

				if (data.value.code !== 200) {
					return {
						error: data.value.message,
						code: data.value.code,
					};
				}

				// Set up currentUser
				const user = data.value.data;
				this.currentUser = user;

				// Add uId to localStorage
				const uId = this.currentUser.uId;
				localStorage.setItem("uId", uId);

				return {
					message: "User logged in.",
					code: 200,
				};
			} catch (error) {
				return {
					error: "Error logging in. Please try again.",
					code: 500,
				};
			}
		},

		//Get user details. Currently only used for admin.
		async hydrateUserDetails(uId, simpleGet = true) {
			const { data: user, error } = await useFetch(
				`/api/users/${uId}`,
				{
					params: simpleGet
						? { simpleGet }
						: { simpleGet: false },
				}
			);

			if (error.value) {
				console.error(
					"Error fetching user details.",
					error.message
				);

				return false;
			}

			//NOTE: Simple get is used to get the user details without setting the currentUser state. This is used for pulling specific user data, such as when creating notifications when the user is not necessarily the current user.
			if (user.value.code === 200) {
				if (simpleGet) {
					return user.value.data;
				} else {
					this.currentUser = user.value.data;
					this.isSignedIn = true;

					return true;
				}
			}

			return false;
		},

		//Used on confirm page to check if user is in database before signing them into the app.
		async checkUserExists(firstName, lastName, email) {
			const { data, error } = await useFetch(
				"/api/users/clients/check",
				{
					method: "POST",
					body: {
						firstName,
						lastName,
						email,
					},
				}
			);

			console.table(data.value);

			if (error.value) {
				console.error(
					"Error checking user exists.",
					error.message
				);

				return false;
			}

			if (data.value.code !== 200) {
				return {
					error: data.value.message,
					code: data.value.code,
				};
			}

			if (data.value.code === 200) {
				this.currentUser = data.value.data;

				// Add uId to localStorage
				const uId = this.currentUser.uId;
				localStorage.setItem("uId", uId);
				return true;
			}

			return false;
		},

		//Update user profile
		async updateUser(update) {
			const { data, error } = await useFetch(
				"/api/users/:uId",
				{
					method: "PATCH",
					body: {
						uId: this.currentUser.uId,
						update,
					},
				}
			);

			if (error.value) {
				throw createError({
					statusCode: 500,
					message: `Error updating user profile. ${error.message}`,
				});
			}

			if (data.value.code === 200) {
				//Refresh user with new details
				this.logIn({
					email: this.currentUser.email,
				});

				return true;
			}
		},
		async isUserAClient() {
			if (this.currentUser.meta.type === "client") {
				//this.isClient = true;
				return true;
			} else {
				//this.isClient = false;
				return false;
			}
		},
		//NOTE: Don't think this is used
		// async getClients(group = "", id = "") {
		// 	if (group === "") {
		// 		try {
		// 			this.users =
		// 				await UserService.getUserType("client");
		// 		} catch (error) {
		// 			this.error = error.message;
		// 		}
		// 	} else if (group === "single") {
		// 		try {
		// 			this.users = await UserService.getUserTypeById(
		// 				"client",
		// 				id
		// 			);
		// 		} catch (error) {
		// 			this.error = error.message;
		// 		}
		// 	}
		// },
		logOut() {
			//Reset the state
			this.$reset(); //NOTE: Not sure if this actually works. Might need to be called from the template/component on the store itself.
		},
	},
});
