import { useNotificationStore } from "@/stores/notifications";

export default async id => {
	console.log("id: ", id);
	const { data: notificationsData } = await useFetch(
		`/api/notifications/${id}`
	);

	const notificationsStore = useNotificationStore();

	if (notificationsData.value.code === 200) {
		notificationsStore.hydrateNotifications(
			notificationsData.value.data
		);
	} else {
		//FIXME: Implement proper error handling
		notificationsStore.error =
			notificationsData.value.message;
	}
};
