import { POST_STATES } from "@/utils/constants.js";
import { usePostStore } from "@/stores/posts";

export default async (
	item,
	url,
	itemStatus,
	isRescheduled
	//isRevived
	//isReassigned
) => {
	let updateData = {};

	console.log("item: ", item);

	if (url === "posts") {
		//Increment updateCount by 1
		item.meta.updateCount++;

		updateData = {
			subject: item.subject,
			content: item.content,
			client: item.meta.client,
			creator: item.meta.creator,
			assignee: item.meta.assignee,
			reviewer: item.meta.reviewer,
			currentStatus: itemStatus,
			currentOwner: item.meta.currentOwner,
			type: item.type,
			updateCount: item.meta.updateCount,
			files: item.meta.files,
			poll: item.meta.poll,
			notes: item.meta.notes,
			ipId: item.ipId, //Specific to usePATCH
			dates: item.meta.dates, //Specific to usePATCH
		};

		const createDate = () => {
			//Match the itemStatus to the post state constants
			const dateType = POST_STATES[itemStatus].dateName;
			const date = new Date();

			if (dateType !== "dateNew") {
				//Check if iteration exists using optional chaining
				if (updateData.dates[dateType]?.iteration) {
					//If iteration exists, add updateCount to iteration array and add date to updates array
					updateData.dates[dateType].iteration.push(
						updateData.updateCount
					);
					updateData.dates[dateType].updates.push(date);
				} else {
					//If iteration does not exist, add iteration and set it to 1
					updateData.dates[dateType] = {
						iteration: [1],
						updates: [date],
					};
				}

				//If dateType is datePublished/dateDeclined, add null date to dateScheduled to prevent possibility of post publishing again in the future
				if (
					dateType === "datePublished" ||
					dateType === "dateDeclined"
				) {
					updateData.dates.dateScheduled.iteration.push(
						updateData.updateCount
					);
					updateData.dates.dateScheduled.updates.push(null);
				}
			} else {
				updateData.dates[dateType] = date;
			}

			//If isRescheduled is true, update the dateScheduled object
			if (isRescheduled) {
				const scheduledDate =
					usePostStore().post.dateScheduled;
				updateData.dates.dateScheduled.iteration.push(
					updateData.updateCount
				);
				updateData.dates.dateScheduled.updates.push(
					scheduledDate
				);
			}

			//If isRevived is true, update the dateRevived object and update dateScheduled to null so that the post has to be rescheduled to be published
			// if (isRevived) {
			// 	if (updateData.dates.dateRevived?.iteration) {
			// 		updateData.dates.dateRevived.iteration.push(
			// 			updateData.updateCount
			// 		);
			// 		updateData.dates.dateRevived.updates.push(date);
			// 	} else {
			// 		updateData.dates.dateRevived = {
			// 			iteration: [1],
			// 			updates: [date],
			// 		};
			// 	}

			// 	updateData.dates.dateScheduled.iteration.push(
			// 		updateData.updateCount
			// 	);
			// 	updateData.dates.dateScheduled.updates.push(null);
			// }

			//If the post has been reassigned, update the dateReassigned object
			// if (isReassigned) {
			// 	//Check if the assignee has changed since the last iteration
			// 	if (
			// 		updateData.dates.dateReassigned?.assignee !==
			// 		item.meta.assignee
			// 	) {
			// 		updateData.dates.dateReassigned.iteration.push(
			// 			updateData.updateCount
			// 		);
			// 		updateData.dates.dateReassigned.updates.push(
			// 			date
			// 		);
			// 		updateData.dates.dateReassigned.assignor =
			// 			item.meta.creator;
			// 		updateData.dates.dateReassigned.assignee =
			// 			item.meta.assignee;
			// 	}
			// }
		};

		//Using the itemStatus, identify the dates that need updating in the dates object in updateData
		//TODO: Error handling for when the itemStatus is not recognised, or when the itemStatus is not a valid status
		createDate();
	} else if (url === "notifications") {
		if (item.isRead) {
			updateData = {
				meta: {
					isRead: true,
				},
				nId: item.nId,
			};
		}
	}

	try {
		const { data: status } = await useFetch(
			`/api/${url}/edit`,
			{
				method: "PATCH",
				body: updateData,
			}
		);

		if (status.value.code === 200) {
			//If the post is to be published, then call useSharePost to share it
			if (itemStatus === "published") {
				const { sharePost } = useSharePost();

				const shareStatus = await sharePost(
					item.type,
					item.content
				);

				if (shareStatus) {
					console.log("useSharePost worked");
				} else {
					console.log("useSharePost failed");
				}
			}

			return status;
		} else {
			return status.value.message;
		}
	} catch (error) {
		return error;
	}
};
