import { defineStore } from "pinia";

export const useStatsStore = defineStore("stats", {
	state: () => ({
		statistics: {},
		error: null,
		isFetching: true,
	}),
	getters: {
		getStats: state => state.statistics,
	},
	actions: {
		hydrateStats(stats) {
			this.statistics = stats;
		},
		// async fetchStats() {
		// 	try {
		// 		const userStore = useUserStore();
		// 		const sId = await userStore.currentUser.sId;

		// 		//TODO: What happens if there just aren't any notificaitons yet, which there won't be when a user first logs on, or if they've deleted/read all their notificaitons?
		// 		const statistics = await StatisticsService.getStats(
		// 			sId
		// 		);

		// 		if (statistics) {
		// 			this.statistics = statistics[0].items;
		// 			this.error = null;
		// 		} else {
		// 			this.statistics = null;
		// 			this.error =
		// 				"Statistics are currently unavailable. Please refresh the window to try again.";
		// 		}
		// 	} catch (err) {
		// 		console.log(err);
		// 	}
		// },
	},
});
