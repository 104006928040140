export default async (
	item,
	url,
	itemStatus,
	isRescheduled
	//isReassigned
) => {
	let body = "";

	//If url is posts, then we need to add dates
	if (url === "posts") {
		console.log("item: ", item);
		console.log("itemStatus: ", itemStatus);

		//Increment updateCount by 1
		item.meta.updateCount++;

		let defaultBody = {
			subject: item.subject,
			content: item.content,
			client: item.meta.client,
			creator: item.meta.creator,
			assignee: item.meta.assignee,
			reviewer: item.meta.reviewer,
			currentStatus: itemStatus,
			currentOwner: item.meta.currentOwner,
			type: item.type,
			updateCount: item.meta.updateCount,
			files: item.meta.files,
			poll: item.meta.poll,
			notes: item.meta.notes,
		};

		const bodyOptions = {
			inProgress: {
				...defaultBody,
				dates: {
					dateNew: new Date(),
					dateProgress: {
						iteration: [1],
						updates: new Date(),
					},

					dateScheduled: {
						iteration: [1],
						updates: new Date(item.dateScheduled),
					},
					// ...(isReassigned
					// 	? {
					// 			dateReassigned: {
					// 				iteration: [1],
					// 				updates: new Date(),
					// 				assignor: item.meta.creator,
					// 				assignee: item.meta.assignee,
					// 			},
					// 		}
					// 	: {}),
				},
			},
			inReview: {
				...defaultBody,
				dates: {
					dateNew: new Date(),
					dateReview: {
						iteration: [1],
						updates: new Date(),
					},
					dateScheduled: {
						iteration: [1],
						updates: new Date(item.dateScheduled),
					},
					// ...(isReassigned
					// 	? {
					// 			dateReassigned: {
					// 				iteration: [1],
					// 				updates: new Date(),
					// 				assignor: item.meta.creator,
					// 				assignee: item.meta.assignee,
					// 			},
					// 		}
					// 	: {}),
				},
			},
			created: {
				...defaultBody,
				dates: {
					dateNew: new Date(),
					dateCreated: {
						iteration: [1],
						updates: new Date(),
					},
					dateScheduled: {
						iteration: [1],
						updates: new Date(item.dateScheduled),
					},
					// ...(isReassigned
					// 	? {
					// 			dateReassigned: {
					// 				iteration: [1],
					// 				updates: new Date(),
					// 				assignor: item.meta.creator,
					// 				assignee: item.meta.assignee,
					// 			},
					// 		}
					// 	: {}),
				},
			},
			published: {
				...defaultBody,
				dates: {
					dateNew: new Date(),
					dateCreated: {
						iteration: [1],
						updates: new Date(),
					},
					dateScheduled: {
						iteration: [1],
						updates: new Date(),
					},
					datePublished: {
						iteration: [1],
						updates: new Date(),
					},
					// ...(isReassigned
					// 	? {
					// 			dateReassigned: {
					// 				iteration: [1],
					// 				updates: new Date(),
					// 				assignor: item.meta.creator,
					// 				assignee: item.meta.assignee,
					// 			},
					// 		}
					// 	: {}),
				},
			},
			declined: {
				...defaultBody,
				dates: {
					dateNew: new Date(),
					dateCreated: {
						iteration: [1],
						updates: new Date(),
					},
					dateDeclined: {
						iteration: [1],
						updates: new Date(),
					},
					// ...(isReassigned
					// 	? {
					// 			dateReassigned: {
					// 				iteration: [1],
					// 				updates: new Date(),
					// 				assignor: item.meta.creator,
					// 				assignee: item.meta.assignee,
					// 			},
					// 		}
					// 	: {}),
				},
			},
			acceptedByAdmin: {
				...defaultBody,
				dates: {
					dateNew: new Date(),
					dateCreated: {
						iteration: [1],
						updates: new Date(),
					},
					dateScheduled: {
						iteration: [1],
						updates: new Date(item.dateScheduled),
					},
					dateAdminAccepted: {
						iteration: [1],
						updates: new Date(),
					},
					// ...(isReassigned
					// 	? {
					// 			dateReassigned: {
					// 				iteration: [1],
					// 				updates: new Date(),
					// 				assignor: item.meta.creator,
					// 				assignee: item.meta.assignee,
					// 			},
					// 		}
					// 	: {}),
				},
			},
		};

		body = bodyOptions[itemStatus] || defaultBody;

		//If url is notifications, then we need to add meta
	} else if (url === "notifications") {
		console.log("item: ", item);

		body = item;
	}

	//Call API
	try {
		//Post to database
		const { data: status } = await useFetch(
			`/api/${url}/new`,
			{
				method: "POST",
				body,
			}
		);

		console.log("status: ", status);

		return status;
	} catch (error) {
		return error;
	}
};
