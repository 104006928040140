import { useAdminStore } from "@/stores/admin";

export default async () => {
	const { data: staffData } = await useFetch(
		"/api/users/staff"
	);

	const adminStore = useAdminStore();

	if (staffData.value.code === 200) {
		adminStore.hydrateStaff(staffData.value.data);
	} else {
		adminStore.error = staffData.value.message;
	}
};
