import { defineStore } from "pinia";

export const useAdminStore = defineStore("admin", {
	state: () => ({
		clients: [],
		staff: [],
		posts: [],
		statistics: [],
		currentClient: {
			client: {},
			posts: [],
		},
		error: null,
	}),
	getters: {
		getClients: state => state.clients,
		getClientsAndIds: state => {
			return state.clients.map(client => {
				return {
					firstName: client.firstName,
					lastName: client.lastName,
					uId: client.uId,
				};
			});
		},
		getClientsAndEmails: state => {
			return state.clients.map(client => {
				return {
					firstName: client.firstName,
					lastName: client.lastName,
					email: client.email,
					uId: client.uId,
				};
			});
		},
		getAdmins: state => state.staff,
		getAll: state => {
			//Get all clients and all staff and return them as one array
			return [...state.clients, ...state.staff];
		},
		getAdminsUserIds: state => {
			return state.staff
				.filter(user => user.level === 3)
				.map(user => user.uId);
		},
		getAdminsAndEmails: state => {
			return state.staff.map(admin => {
				return {
					firstName: admin.firstName,
					lastName: admin.lastName,
					email: admin.email,
					uId: admin.uId,
				};
			});
		},
		getFirstClientInList: state => {
			return state.clients[0];
		},
	},
	actions: {
		hydrateClients(clients) {
			this.clients = clients;
		},
		hydrateStaff(staff) {
			this.staff = staff;
		},
		hydrateStats(stats) {
			this.stats = stats;
		},
		hydratePosts(posts) {
			this.posts = posts;
		},
		getSender(sender) {
			//Match sender against getAll array and return the name of the sender
			const senderName = this.getAll.find(
				user => user.uId === sender
			).firstName;

			return senderName;
		},
		getRecipients(recipients) {
			//Match recipients against getAll array and return the names of the recipients
			const recipientsNames = recipients.map(recipient => {
				return this.getAll.find(
					user => user.uId === recipient
				).firstName;
			});

			return recipientsNames;
		},
		async getAllUsers(userType = "all") {
			if (userType === "all") {
				//Get all clients and all staff
				await useClients();
				await useStaff();
			} else if (userType === "clients") {
				//Get all clients
				await useClients();
			} else {
				//Get all staff
				await useStaff();
			}
		},
		async getCurrentClient(client) {
			//Match client against clients array and return the first name and the company of the client
			this.currentClient.client = client;

			//Get the posts of the client
			await usePosts(client.uId, "client", true);

			return {
				firstName: this.currentClient.client.firstName,
				lastName: this.currentClient.client.lastName,
				title: this.currentClient.client.title,
				company: this.currentClient.client.company,
				uId: this.currentClient.client.uId,
			};
		},
	},
});
